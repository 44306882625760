<template>
  <header>
    <div class="container flex justify-start">
      <svg
              viewBox="0 0 100 100" width="80"
              :class="menu_mobile ? 'menu-mobile ham hamRotate ham4 active' : 'menu-mobile ham hamRotate ham4'"
              v-on:click="menu_mobile=!menu_mobile"
      >
        <path
                class="line top"
                d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20"/>
        <path
                class="line middle"
                d="m 70,50 h -40"/>
        <path
                class="line bottom"
                d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20"/>
      </svg>
      <router-link to="/" class="logo">
        <img src="../../assets/img/logos.png" alt="" width="60">
      </router-link>
      <ul
              :class="menu_mobile ? 'top-menu open' : 'top-menu'"
              v-on:click="menu_mobile=false"
      >
        <li class='top-menu__item active'><a href="https://ns-touch.com/">TOUCH SWITCHES</a></li>
        <li class='top-menu__item active'>
          <router-link to="/">Create Touch</router-link>
        </li>
        <li class='top-menu__item active'><a href="https://ns-touch.com/relay-station.html">Relay Station</a></li>
        <li class='top-menu__item active'><a href="https://ns-touch.com/ready-offers.html">Ready Offers</a></li>
        <li class='top-menu__item active'><a href="https://ns-touch.com/gallery.html">Gallery</a></li>
        <li class='top-menu__item active'><a href="https://ns-touch.com/downloads.html">Downloads</a></li>
        <li class='top-menu__item active'><a href="https://ns-touch.com/contacts.html">Contacts</a></li>
        <li v-if="!user.login" class='top-menu__item active'>
          <a @click="openModalAuthentication" href="#">
            Login
            <img src="../../assets/img/cabinet/login-1.png" alt="" width="">
          </a>
        </li>
        <li v-if="user.login" class='top-menu__item active'>
          <a href="">
            User
            <img src="../../assets/img/cabinet/user-2.png" alt="" width="">
          </a>
          <ul>
            <li><p>{{user.email}}</p></li>
            <li>
              <router-link :to="{ path: '/cabinet'}">Personal cabinet</router-link>
            </li>
            <li><a @click="userExit" href="">
              Exit
              <img src="../../assets/img/cabinet/login-1.png" alt="" width="">
            </a></li>
          </ul>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
    import {mapGetters, mapMutations} from "vuex";

    export default {
        name: "Header",
        data(){
          return{
            menu_mobile: false
          }
        },
        computed: {
            ...mapGetters({
                user: "Cabinet/user",
            })
        },
        methods: {
            ...mapMutations({
                MODAL_AUTHENTICATION: "Cabinet/MODAL_AUTHENTICATION",
                USER_LOGOUT: "Cabinet/USER_LOGOUT",
                RESET_COLLECTION: "Collection/RESET_COLLECTION",
            }),
            openModalAuthentication(event) {
                event.preventDefault()
                this.MODAL_AUTHENTICATION(true)
            },
            userExit(event) {
                event.preventDefault()
                this.USER_LOGOUT()
                this.RESET_COLLECTION()
            },
        },
    }
</script>
