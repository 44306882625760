<template>
  <div id="app">
    <!--    <div id="nav">-->
    <!--      <router-link to="/">Home</router-link> |-->
    <!--      <router-link to="/about">About</router-link>-->
    <!--    </div>-->
    <Header/>
    <router-view/>
<!--    <ModalAuthentication @close="closeModalAuthentication" :isVisible="showModalAuthentication"/>-->
<!--    <ModalSaveCollection @close="closeModalSaveCollection" :isVisible="showModalSaveCollection"/>-->
  </div>
</template>

<script>
    import Header from '@/components/Header'
    // import ModalAuthentication from '@/components/Modals/Authentication'
    // import ModalSaveCollection from '@/components/Modals/SaveCollection'
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: "App",
        components: {
            Header,
            // ModalAuthentication,
            // ModalSaveCollection,
        },
        computed: {
            ...mapGetters({
                // user: "Cabinet/user",
                // showModalAuthentication: "Cabinet/showModalAuthentication",
                // showModalSaveCollection: "Cabinet/showModalSaveCollection",
            })
        },
        methods: {
            ...mapActions({
                // GET_COLLECTION: 'Collection/getCollection',
            }),
            ...mapMutations({
                // MODAL_AUTHENTICATION: "Cabinet/MODAL_AUTHENTICATION",
                // MODAL_SAVE_COLLECTION: "Cabinet/MODAL_SAVE_COLLECTION",
                GET_USER: "Cabinet/GET_USER",
                // CLEAR_MESSAGE: "Collection/CLEAR_MESSAGE",
            }),
            // closeModalAuthentication() {
            //     this.MODAL_AUTHENTICATION(false)
            // },
            // closeModalSaveCollection() {
            //     // this.CLEAR_MESSAGE()
            //     this.MODAL_SAVE_COLLECTION(false)
            // },
        },
        beforeMount() {
            // let params = (new URL(document.location)).searchParams;
            // if(params.get("collection_id")){
            //     this.GET_COLLECTION({collection_id: params.get("collection_id")})
            // }
            this.GET_USER()
        },
        // updated() {
        //     let params = (new URL(document.location)).searchParams;
        //     if(params.get("collection_id")){
        //         this.GET_COLLECTION({collection_id: params.get("collection_id")})
        //     }
        // }
    }
</script>
