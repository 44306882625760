// import mainApi from "../../api/main-api";

export default {
    SET_COLLECTION(state, payload) {

        state.collection = payload.collection;
        state.collection_id = payload.collection_id
        state.collection_name = payload.collection_name
        // let test = JSON.stringify(payload.collection)
        // console.log(test)
        // debugger
        // localStorage.setItem('collection', JSON.stringify(state.collection));
        // localStorage.setItem('collection_id', JSON.stringify(payload.collection_id));
        // localStorage.setItem('collection_name', JSON.stringify(payload.collection_name));
    },
    RESET_COLLECTION(state) {
        state.collection = [];
        state.collection_id = '';
        state.collection_name = '';
        localStorage.setItem('collection', JSON.stringify(state.collection));
        localStorage.setItem('collection_id', JSON.stringify(false));
        localStorage.setItem('collection_name', JSON.stringify(false));
    },
    ADD_TO_COLLECTION(state, payload) {
        state.collection.push(payload)
        localStorage.setItem('collection', JSON.stringify(state.collection));
    },
    UPDATE_COLLECTION_ITEM(state, {i, newItem}) {
        state.collection.splice(i, 1, newItem);
        localStorage.setItem('collection', JSON.stringify(state.collection));
    },
    DELETE_FROM_COLLECTION(state, i) {
        state.collection.splice(i, 1);
        localStorage.setItem('collection', JSON.stringify(state.collection));
        if (state.collection.length === 0){
            localStorage.setItem('collection_id', JSON.stringify(false));
            localStorage.setItem('collection_name', JSON.stringify(false));
        }
    },
    SAVE_COLLECTION_NAME(state, payload) {
        state.collection_id = payload.collection_id
        state.collection_name = payload.collection_name
        localStorage.setItem('collection_id', JSON.stringify(payload.collection_id));
        localStorage.setItem('collection_name', JSON.stringify(payload.collection_name));
    },
    SAVE_COLLECTION(state, payload) {
        state.collection = JSON.parse(payload.collection)
        state.collection_id = payload.collection_id
        state.collection_name = payload.collection_name
        state.collections_history = payload.collections_history
        state.collection_ordered = payload.collection_ordered
        localStorage.setItem('collection_id', JSON.stringify(payload.collection_id));
        localStorage.setItem('collection_name', JSON.stringify(payload.collection_name));
        localStorage.setItem('collection_ordered', JSON.stringify(payload.collection_ordered));
        localStorage.setItem('collection', payload.collection);
    },
    SAVE_COLLECTIONS(state, payload) {
        state.collection_names = payload.collection_names
    },
    CHANGE_COLLECTION_NAME(state, payload) {
        state.collection_name = payload.collection_name
    },
    CLEAR_MESSAGE(state) {
        state.messageSaveCollection = ''
    },
    // GET_COLLECTION(state, params) {
    //     let token = localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : []
    //     mainApi.getCollection(params, token).then(response => {
    //         state.collection_id = response.collection_id
    //         state.collection_name = response.collection_name
    //         state.collection = JSON.parse( response.collection )
    //
    //         localStorage.setItem('collection_id', JSON.stringify(response.collection_id));
    //         localStorage.setItem('collection_name', JSON.stringify(response.collection_name));
    //         localStorage.setItem('collection', response.collection);
    //         // debugger
    //     });
    // },
}
