<template>
  <div id="Content" class="container cabinet_collection">
    <div class="Nav-NsTouch">
      <router-link :to="{ path: '/'}">
        <h2 class="">
          Home
        </h2>
      </router-link>
      <span class="arrow"/>
      <router-link :to="{ path: '/cabinet'}">
        <h2 class="">
          Personal cabinet
        </h2>
      </router-link>
      <span v-show="collection_name" class="arrow"/>
      <h2 v-show="collection_name" class="active">{{collection_name}}</h2>
    </div>

    <div class="user_panel">
      <div class="left">
        <router-link :to="{ path: '/?collection_id='+collection_id}" class="text-primary">
          Open for editing / Save as PDF
        </router-link>
        <p v-if="!collection_ordered" @click="sendToOrder" class="text-primary as_link">Send to order</p>
        <p v-if="collection_ordered" class="text-primary done">
          <img src="../../assets/img/cabinet/done.png" alt="" width="">Collection ordered
        </p>


      </div>
      <div class="history">
        <h3 class="" style="">Update history</h3>
        <ul>
          <li
              class="list-group-item"
              v-for="history in collections_history"
              v-bind:key="history.id"
          >
            <router-link :to="{ path: '/?collection_id='+collection_id+'&history_id='+history.id}" class="text-primary">
              {{ history.date_updated }} - {{history.user_email}}
            </router-link>
          </li>
        </ul>
      </div>
    </div>


    <Collection @change-tab="changeTab"></Collection>


  </div>
</template>

<script>


    import {mapActions, mapGetters, mapMutations} from "vuex";
    import Collection from '../../components/Collection'

    export default {
        name: 'CabinetCollection',
        components: {
            Collection,
        },
        computed: {
            ...mapGetters({
                // collection_names: "Collection/collection_names",
                collection_name: "Collection/collection_name",
                collection_id: "Collection/collection_id",
                collections_history: "Collection/collections_history",
                collection_ordered: "Collection/collection_ordered",
            })
        },
        methods: {
            ...mapActions({
                GET_COLLECTION: 'Collection/getCollection',
                SEND_TO_ORDER: 'Collection/sendToOrder',
            }),
            ...mapMutations({
                // MODAL_AUTHENTICATION: "Cabinet/MODAL_AUTHENTICATION",
                RESET_COLLECTION: "Collection/RESET_COLLECTION",
            }),
            sendToOrder() {
                this.$swal({
                    title: 'Send to order ?',
                    // text: 'After submitting, you will not be able to edit the collection, but you can create a new one based on this',
                    // input: 'text',
                    // inputAttributes: {
                    //   autocapitalize: 'off'
                    // },
                    showCancelButton: true,
                    confirmButtonText: 'Submit',
                    cancelButtonText: 'Cancel',
                    showLoaderOnConfirm: true,
                }).then((result) => {
                    if(result.isConfirmed) {
                        this.SEND_TO_ORDER({collection_id: this.collection_id})
                    }
                })
            },
            closeModalAuthentication() {
                // this.MODAL_AUTHENTICATION(false)
            },
            closeModalSaveCollection() {
                // this.MODAL_SAVE_COLLECTION(false)
            },
            changeTab() {
                // if (val !== 'switch') {
                //     this.tab = val
                // } else {
                //     this.tab = ''
                // }
            },
        },
        data() {
            return {
                orderName: "",
            }
        },
        beforeMount() {
            this.RESET_COLLECTION()
            let params = (new URL(document.location)).searchParams;
            if (params.get("id")) {
                this.GET_COLLECTION({collection_id: params.get("id")})
            }
        },

    }
</script>

<style lang="scss">

</style>
