<template>
  <div id="Content" class="container">
    <div class="Nav-NsTouch">
      <router-link :to="{ path: '/'}">
        <h2 class="">
          Home
        </h2>
      </router-link>
      <span class="arrow"/>
      <h2 class="active">Personal cabinet</h2>
    </div>
    <ul>
      <li
        class="list-group-item"
        v-for="collection_name in collection_names"
        v-bind:key="collection_name.id"
      >
<!--        <router-link :to="{ path: '/cabinet/collection?id='+collection_name.id}" class="text-primary">-->
<!--          {{ collection_name.collection_name }}-->
<!--        </router-link>-->
        <router-link :to="{ path: '/?collection_id='+collection_name.id}" class="text-primary">
          {{ collection_name.collection_name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>


    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: 'ShowCabinet',
        components: {},
        computed: {
            ...mapGetters({
                collection_names: "Collection/collection_names",
            })
        },
        methods: {
            ...mapActions({
                GET_COLLECTIONS: 'Collection/getCollections',
            }),
            ...mapMutations({
                // MODAL_AUTHENTICATION: "Cabinet/MODAL_AUTHENTICATION",
            }),
            closeModalAuthentication() {
                // this.MODAL_AUTHENTICATION(false)
            },
            closeModalSaveCollection() {
                // this.MODAL_SAVE_COLLECTION(false)
            },
        },
        data() {
            return {
                orderName: "",
            }
        },
        beforeMount() {
            this.GET_COLLECTIONS()
        },

    }
</script>

<style lang="scss">

</style>
